<template>
    <div class="m-2">
        <h2>Daily</h2>
        <snapshots-chart v-if="snapshots.length > 0" :snapshots="snapshots" :maps="maps" :countKey="countKey" />
        <b-tabs>
            <b-tab title="Names">
                <p>
                    Number of results: {{ numMaps }}<br />
                </p>
                <b-input-group>
                    <b-input-group-prepend>
                        <b-input-group-text>
                            <font-awesome-icon icon="search" />
                        </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input v-model="search" placeholder="Search maps by name, author, tags"
                        @keydown.enter.native="onSearch"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="outline-secondary" @click="search = ''">
                            <font-awesome-icon icon="times" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <div class="form-inline sub-mt-2 sub-mr-2">
                    <b-form-checkbox v-model="useSearchTerms">Use Search Terms:</b-form-checkbox>
                    <b-badge v-for="term in searchTerms" :key="term">{{ term }}</b-badge>
                </div>
                <div class="form-inline sub-mt-2 sub-mr-2">
                    <div>Options:</div>
                    <div>
                        <b-form-checkbox v-model="aggregateSearchResults">Aggregate Search Results</b-form-checkbox>
                    </div>
                </div>
                <b-button variant="primary" @click="prepareSnapshots">Update</b-button>
            </b-tab>
            <b-tab title="XP">
                <p v-if="false">
                    Number of Map Infos loaded: {{ mapInfos_asArray.length }}<br />
                    Number of Maps in Top 300 with XP enabled: {{ xpMapCount.enabled }} / not {{ xpMapCount.notEnabled }} /
                    unknown
                    {{ xpMapCount.unknown }}<br />
                    Number of Average players in Top 300 maps with XP enabled: {{ xpPlayerCount.enabled }} / not {{
                        xpPlayerCount.notEnabled }} / unknown {{ xpPlayerCount.unknown }}<br />
                    <b-button variant="primary" @click="processRatioXP">Calculate Latest</b-button>
                </p>
                <p class="mt-2">
                    Displays data about XP (Creative) or UEFN in the top 300 maps.<br/>
                    Note: Epic Games maps are reported as not xp enabled but they are.
                </p>
                <div class="form-inline sub-mt-2 sub-mr-2">
                    <div>Options:</div>
                    <b-form-select id="selector" class="col" v-model="selectedOption">
                        <option value="all">All</option>
                        <option value="numbered">Community Only</option>
                        <option value="epic">Epic Only</option>
                    </b-form-select>
                    <b-form-select id="selector" class="col" v-model="showXpCount">
                        <option value="map">Show Map Count</option>
                        <option value="player">Show Player Count</option>
                    </b-form-select>
                </div>
                <div class="form-inline sub-mt-2 sub-mr-2">
                    <b-button variant="primary" @click="processRatioXPChart">Display</b-button>
                    <b-button variant="secondary" @click="collectIslandStats" v-if="false">Collect Unknown Island Stats</b-button>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { getLog } from '@/services/log'
const log = getLog('charts3');

import { db } from '@/services/db'
import { loadArr } from '@/services/dbutils'
import { bindMap } from '@/services/utils'
import { checkIslandsStats } from '@/services/functions'

export default {
    components: {
        SnapshotsChart: () => import('./components/SnapshotsChart.vue')
    },
    data() {
        return {
            mapInfos: {},
            mapInfos_asArray: [],
            xpMapCount: {},
            xpPlayerCount: {},
            showXpCount: 'player',
            unknownMapsIslandCodes: {},

            allSnapshots: [],
            snapshots: [],
            maps: [],
            countKey: 'playerCount',

            selectedOption: 'numbered',
            search: '',
            numMaps: 0,

            useSearchTerms: true,
            aggregateSearchResults: false,
            searchTerms: ['1v1', 'gun game', 'Box', '2v2', 'zombie', 'survi', 'pit', 'zone wars', 'Free For All', 'Deathrun', 'one shot'],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        processMaps(maps, search) {
            search = search.toLowerCase();
            return maps.filter(map => {
                return (map.name.toLowerCase().includes(search)
                    || map.author?.toLowerCase().includes(search)
                    || map.islandCode.toLowerCase().includes(search)
                    || map.tags?.some(tag => tag.toLowerCase().includes(search)))
                    && (this.selectedOption === 'all'
                        || this.selectedOption === 'numbered' && /^\d/.test(map.islandCode)
                        || this.selectedOption === 'epic' && !/^\d/.test(map.islandCode))
            });
        },
        // add all the players for these maps into one map
        addPlayerCounts(maps, name) {
            let playerCount = 0;
            maps.forEach(map => {
                playerCount += map.playerCount;
            });
            return this.statToMaps(playerCount, name);
        },
        statToMaps(value, name) {
            return [{
                name: name || "all",
                islandCode: name || "all",
                playerCount: value,
            }];
        },
        mapsFromSnapshots(snapshots) {
            let maps = {};
            snapshots.findLast(snapshot => {
                snapshot.maps.forEach(map => {
                    if (!maps.hasOwnProperty(map.islandCode))
                        maps[map.islandCode] = map;
                })
            });
            return Object.values(maps).sort((a, b) => b[this.countKey] - a[this.countKey]);
        },
        async init() {
            log.log('init');
            bindMap(this, 'mapInfos', db.collection('ScrapFNMapsInfo'));
            this.allSnapshots = await loadArr(db.collection('ScrapFNSnapshotsDaily'));
            log.log('allSnapshots', this.allSnapshots);
            this.prepareSnapshots();
        },
        onSearch(event) {
            if (event.which === 13)
                this.prepareSnapshots();
        },
        prepareSnapshots() {
            this.snapshots = this.allSnapshots.map(snap => {
                let maps = [];
                let snapshot = { ...snap };
                if (!this.useSearchTerms) {
                    maps = this.processMaps(snapshot.maps, this.search);
                    this.numMaps = maps.length;
                    if (this.aggregateSearchResults)
                        snapshot.maps = this.addPlayerCounts(maps, this.search);
                } else {
                    // for each search term call processMaps and addPlayerCounts
                    let maps = [];
                    this.searchTerms.forEach(term => {
                        let termMaps = this.processMaps(snapshot.maps, term);
                        maps = maps.concat(this.addPlayerCounts(termMaps, term));
                    });
                    this.numMaps = maps.length;
                    snapshot.maps = maps;
                }
                log.log('snapshot', snapshot);
                return snapshot;
            });
            this.maps = this.mapsFromSnapshots(this.snapshots);
            log.log('snapshots', this.snapshots, 'maps', this.maps);
        },
        processRatioXP() {
            // for each map in the latest snapshot, get the map info and check if it has .xp.enabled == true
            let xpMapCount = { enabled: 0, notEnabled: 0, unknown: 0 };
            let xpPlayerCount = { enabled: 0, notEnabled: 0, unknown: 0 };
            this.unknownMapsIslandCodes = {};

            let snapshot = this.allSnapshots[this.allSnapshots.length - 1];
            log.log("maps count", snapshot.maps.length);
            snapshot.maps.forEach(map => {
                let mapInfo = this.mapInfos[map.islandCode];
                if (mapInfo) {
                    if (mapInfo.xp?.enabled) {
                        xpMapCount.enabled++;
                        xpPlayerCount.enabled += map.playerCount;
                    } else {
                        xpMapCount.notEnabled++;
                        xpPlayerCount.notEnabled += map.playerCount;
                    }
                } else {
                    if (!this.unknownMapsIslandCodes.hasOwnProperty(map.islandCode)) {
                        this.unknownMapsIslandCodes[map.islandCode] = 0;
                        xpMapCount.unknown++;
                    }
                    this.unknownMapsIslandCodes[map.islandCode] += map.playerCount;
                    xpPlayerCount.unknown += map.playerCount;
                }
            });
            this.xpMapCount = xpMapCount;
            this.xpPlayerCount = xpPlayerCount;
        },
        // Same as processRatioXP but for all snapshots
        processRatioXPChart() {
            // for each map in the latest snapshot, get the map info and check if it has .xp.enabled == true
            this.unknownMapsIslandCodes = {};
            this.xpChart = [];
            this.allSnapshots.forEach(snapshot => {
                let xpMapCount = { enabled: 0, notEnabled: 0, unknown: 0 };
                let xpPlayerCount = { enabled: 0, notEnabled: 0, unknown: 0 };
                let maps = this.processMaps(snapshot.maps, this.search);
                maps.forEach(map => {
                    let mapInfo = this.mapInfos[map.islandCode];
                    if (mapInfo) {
                        if (mapInfo.xp?.enabled) {
                            xpMapCount.enabled++;
                            xpPlayerCount.enabled += map.playerCount;
                        } else {
                            xpMapCount.notEnabled++;
                            xpPlayerCount.notEnabled += map.playerCount;
                        }
                    } else {
                        if (!this.unknownMapsIslandCodes.hasOwnProperty(map.islandCode))
                            this.unknownMapsIslandCodes[map.islandCode] = 0;
                        this.unknownMapsIslandCodes[map.islandCode] += map.playerCount;
                        xpMapCount.unknown++;
                        xpPlayerCount.unknown += map.playerCount;
                    }
                });
                // map count
                if (this.showXpCount == "map")
                    this.xpChart.push({
                        timestamp: snapshot.timestamp,
                        maps: [
                            ...this.statToMaps(xpMapCount.enabled, "XP enabled"),
                            ...this.statToMaps(xpMapCount.notEnabled, "XP not enabled"),
                            ...this.statToMaps(xpMapCount.unknown, "XP unknown"),
                        ]
                    });
                else
                    // player count
                    this.xpChart.push({
                        timestamp: snapshot.timestamp,
                        maps: [
                            ...this.statToMaps(xpPlayerCount.enabled, "XP enabled player count"),
                            ...this.statToMaps(xpPlayerCount.notEnabled, "XP not enabled player count"),
                            ...this.statToMaps(xpPlayerCount.unknown, "XP unknown player count"),
                        ]
                    });
            });
            this.snapshots = this.xpChart;
            this.maps = this.mapsFromSnapshots(this.snapshots);
        },
        // batch 10 in a row
        collectIslandStats() {
            log.log('collectIslandStats', this.unknownMapsIslandCodes);
            // sort unknownMapsIslandCodes by value and return the list of keys in the descending order of the values
            let islandCodes = Object.keys(this.unknownMapsIslandCodes).sort((a, b) => this.unknownMapsIslandCodes[b] - this.unknownMapsIslandCodes[a]);
            log.log('islandCodes', islandCodes);
            let batches = [];
            while (islandCodes.length > 0) {
                batches.push(islandCodes.splice(0, 10));
            }
            batches.forEach(async (batch) => {
                let { data } = await checkIslandsStats({ islandCodes: batch });
                log.log('checkIslandsStats', data);
            });
        }
    }
}
</script>